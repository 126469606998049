import * as React from 'react';
import { Row, Cell } from './grid';
import { FormTitle, Button } from './form';
import FormIntro from './form-intro';
import * as styles from './form.module.scss';

const FOIReviewFormStep1 = (props) => {
  const {
    onSubmit,
  } = props;

  return (
    <form
      className={styles.page}
      onSubmit={onSubmit}
      noValidate>
      <FormIntro>
        <FormTitle 
          text="Request an FOI Review"
        />
        <Row>
          <Cell className="large-7">
            <h2>What you should know about using this form</h2>
            <p>Use this form to apply to OVIC for a review of a written decision that you have received from a Victorian public sector agency or Minister in relation to your freedom of information (<strong>FOI</strong>) request.</p>
            <p>Before applying for a review by us it is important to read about <a href="https://ovic.vic.gov.au/freedom-of-information/for-the-public/foi-reviews/" target="_blank" rel="noopener noreferrer"> what we can review, our FOI reviews process, and what happens next.</a></p>
            <h3>How will the information I provide be used?</h3>
            <p>We use the information you provide to help us to undertake a review. This includes contacting the agency or Minster to notify them of the review, discussing the review with them, and collecting information about you from them.</p>
            <p>Information you enter onto this form is stored locally, in your browser, until you submit it. If you do not submit the form, the information you enter will stay stored locally in your browser until you clear the browser cache or delete any information you entered onto the form.</p>
            <h3>Will information be provided to third parties?</h3>
            <p>If the documents you requested contain information relating to another person or business, and we decide to release their information, we are required to notify those individuals or businesses of our decision. They may choose to appeal our decision. Our notification will include your name, what information you requested, and a description of the documents being released.</p>
            <p>We manage personal information in accordance with our <a href="https://ovic.vic.gov.au/about-us/internal-policies-procedures-and-registers/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy.</a></p>
            <Button 
              text="Start your request"
            />
          </Cell>
          <Cell className="large-5">
            <Button 
              text="Start your request"
              className="show-for-large"
            />
            <p><em>This form will take 15 - 30 minutes to complete.</em></p>
            <p><em>You will be emailed a copy of your submission.</em></p>
            <h2>Need help?</h2>
            <p>Contact us by phone on <strong>1300 00 6842</strong> or email at enquiries@ovic.vic.gov.au.</p>
            <h2>More information?</h2>
            <p>You can find more information about OVIC's <a href="https://ovic.vic.gov.au/freedom-of-information/apply-for-a-review/" target="_blank" rel="noopener noreferrer">review application process on our website.</a></p>
            <Button 
              text="Start your request"
              className="hide-for-large"
            />
          </Cell>
        </Row>
      </FormIntro>
    </form>
  )
}

export default FOIReviewFormStep1;
