import * as React from 'react';
import Layout from '../components/layout';
import FOIReviewForm from '../components/foi-review-form';
const formConfig = require('../components/foi-review-form.json');

const FOIReviewPage = () => (
  <Layout
    title={formConfig.title}>
    <FOIReviewForm 
    />
  </Layout>
)

export default FOIReviewPage;
